.import-modal {
   height: 100%;

   &__import-area {
      display: grid;
      column-gap: 30px;

      @media only screen and (min-width: 768px) { grid-template-columns: 1fr 1fr; }

         &__select-unit-button,
         &__position {
            width: 100%;
            height: 100%;
            color: #989898;
            position: relative;
            overflow: auto;
            height: 216px;
            padding: 2px;
         }
         &__select-unit-button,
         &__position:not(&__position--disabled) { cursor: pointer; }

         &__select-unit-button {
            color: black;
            outline: 2px solid rgba(152,152,152,0.62);
            margin-bottom: 28px;
         }

         &__select-unit-button:hover, &__position:not(&__position--disabled):hover {
            background-color: #eeeded;
            outline-color: #0075BE;
            color: #0075BE;
         }

         /*Change p to proper component e.g. h4*/
         &__select-unit-button > p,
         &__position > p {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            top: calc(50% - 12px);
            margin-bottom: -12px;
            font-size: 20px;
         }

         &__position {
            outline: 2px dashed rgba(152,152,152,0.62);

            label {
               position: absolute;
               left: calc(50% - 130px);
               top: calc(50% - 20px);
               margin-bottom: -12px;
               font-size: 20px;
            }

            &--error {
               outline-color: #bf1a1ac2;
            }

            &.is-dragover {
               background-color: #eeeded;
               outline: 2px dashed #0075BE;
               color: #0075BE;
            }

            .file-preview > .image-footer {
               /* position: relative; */
               /* height: 16px; */
               position: absolute;
               bottom: 0;
            }
            .file-preview > .image-footer > p {
               /* position: absolute; */
               /* bottom: 0px; */
               background: black;
               line-height: 11px;
               font-size: 12px;
               color: white;
               padding: 2px 4px;
               margin: 0;
               width: 100%
            }
            .file-preview--error > .image-footer > p {
               color: red;
            }

            .file-preview > .file-preview__already-uploaded-text {
               position: absolute;
               display: block;
               top: 0;
               background: #000000aa;
               padding-top: 80px;
               width: 100%;
               height: 100%;
               text-align: center;
               color: #0075BE;
               font-size: 1.4rem;
               font-weight: normal;
            }

         }

   }
}


input.add-files {
   position: absolute;
   top: 0;
   left: 0;
   width: 0;
   opacity: 0;
   pointer-events: none;
}

div.file-preview-pair-container {
   display: flex;
}

div.file-preview {
   background-color: #000;
   position: relative;
   height: 199px;
   margin: 6px;
   outline: 2px solid #989898;
}
div.file-preview:not(.file-preview--disabled):hover,
div.file-preview:not(.file-preview--disabled):active {
   outline: 3px solid #0075BE;
}
div.file-preview.file-preview--error {
   outline-color: rgb(189, 52, 52);
}
div.file-preview.file-preview--error:hover,
div.file-preview.file-preview--error:active {
   outline: 3px solid rgb(189, 52, 52);
}

div.file-preview-pair-container
div.file-preview {
   width: calc(50% - 12px);
}

div.file-preview img.imported {
   background-color: #fff;
   max-width: 100%;
   max-height: 100%;
}

div.file-preview button {
   display: none;
}




/*Close Button*/
div.file-preview:hover button {
   display: block;
   position: absolute;
   top: 0px;
   right: 0px;
}
div.file-preview:hover button:hover {
   cursor: pointer;
   opacity: 1;
}
div.file-preview button.remove-file-btn {
   height: 24px;
   width: 24px;
   font-size: 18px;
   /* background-color: rgba(0,117,190,0.5); */
   background-color: #0075BE;
}
div.file-preview.file-preview--error button.remove-file-btn {
   background-color: rgb(189, 52, 52);
}

div.file-preview button.remove-file-btn img {
   position: absolute;
   right: 0;
   top: 0;
   height: 22px;
   width: 22px;
}



/*ADD INFO STAGE*/
div.edit-area textarea {
   resize: none;
   min-height: 100px;
   max-height: 200px;
}



.loader-container {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   width: 100%;
   flex-direction: column;
}


.file-preview p.date-str {
   font-size: 16px;
   height: 34px;
   margin: 0;
   position: absolute;
   top: calc(100% + 2px);
   left: 0;
   color: red;
}



.modal-header-unit-dropdown {
   margin-left: 24px;
}



button.button.create-new-tag-btn {
   color: #0075BE;
   margin-left: -6px;
}
