@import '../../../shared';

.page-tool-configuration {

   &__container {
      display: flex;
      flex-direction: row;

   }

   &__inner-container {
      height: calc(100vh - #{$navbar_height});
      overflow-y: hidden;
      width: calc(100% - #{$side_menu_width});
      // padding: 24px;

      &.mobile {
         width: 100%;
         height: calc(100vh - #{$navbar_height});
         padding-top: $navbar_height; // mobile navbar height
      }

   }

}
