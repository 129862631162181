.page-home {
   display: block;
}

.page-home-header {
   width: 100%;
   height: 380px;
   max-height: 100vh;
   position: relative;

   background: url("../../assets/headerFullHd.jpg"); /*url("../../assets/header.png");*/
   background-position: 50% 45%;
   background-size: cover;
   background-repeat: no-repeat;
   width: auto;
}

@media only screen and (min-width: 1920px) {
   .page-home-header {
      height: 500px;
   }
}



/* .page-home-header img {
   width: 100%;
   height: auto;
   position: absolute;
   bottom: 0;
   right: 0;
} */

.page-home-header h1 {
   position: absolute;
   left: 0;
   right: 0;
   padding: 12px;
   top: calc(50% - 30px);
   text-align: center;
   color: #fff;
   font-weight: 400;
}

.page-home h2 {
   color: #989898;
}

.page-home p.subtitle-label {
   margin-bottom: 0;
   color: #0075BE;
   font-weight: 400;
}


.right-info {
   max-width: 230px;
}


.home-page-content {
   padding-top: 30px;
   display: flex;
   margin: auto;
   width: 1570px;
   max-width: 100%;
}

.home-page-content h3.no-sites-label {
   width: 100%;
   text-align: center;
   font-weight: 400;
   color: #989898;
   margin-top: 12px;
}

.main-container {
   /* width: 1340px; */
   width: 100%;
   max-width: 100%;
}

@media only screen and (max-width: 1630px) {
   .home-page-content {
      width: 1302px;
   }

   /* .main-container {
      width: 1140px
   } */
}

@media only screen and (max-width: 1362px) {
   .home-page-content {
      width: 1034px;
   }

   /* .main-container {
      width: 804px;
   } */
}

@media only screen and (max-width: 1092px) {
   .home-page-content {
      width: 804px;
      /* margin-right: 38px; */
   }
   .right-info {
      display: none;
   }
}

@media only screen and (max-width: 767px) { /*mobile*/
   .page-home-header {
      margin-top: 60px;
      height: 280px;
      max-height: calc(100vh - 60px);
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .page-home-header h1 {
      position: static;
      /* margin-bottom: 10px; */
   }
}

/*MOBILE*/
.g-container.mobile {
   max-width: 100vw;
   overflow: hidden;
}
