div.user-modal-content-container p {
   color: #333;
   margin: 0;
}

div.user-modal-content-container p.error-label {
   color: #be0000;
}

div.user-modal-content-container button.button.button {
   margin-left: -8px;
   color: #0075BE;
   width: auto;
}

.change-password-view input {
   margin-bottom: 12px;
}
