div.top-menu {
   padding-top: 12px;
}


div.top-menu button.top-menu-button {
   height: 100%;
   background: transparent;
   border: none;
   outline: none;
   /* margin-top: 8px; */
   margin-right: 20px;
   /* height: 36px; */
   min-width: 80px;
   text-align: center;
   color: #fff;
   border-bottom: 3px solid transparent;
}

div.top-menu button.top-menu-button:hover {
   cursor: pointer;
}

div.top-menu.disabled button.top-menu-button:hover {
   cursor: default;
}

div.top-menu.disabled button.top-menu-button  {
   color: rgba(255, 255, 255, 0.6)
}

div.top-menu button.top-menu-button.selected {
   border-bottom: 3px solid #0075BE;
   color: #fff;
}
