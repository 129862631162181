.loader {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}
.loader > div.loader-progress,
.loader > div.loader-spinner {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  font-size: 10px;
  position: relative;
  border-left: 0.6em solid rgba(0, 0, 0, 0.1);
  border-top: 0.6em solid rgba(0, 0, 0, 0.1);
  border-right: 0.6em solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.6em solid rgba(0, 0, 0, 0.1);
}
.loader > div.loader-progress {
  transform: rotate(270deg);
}
.loader > div.loader-spinner {
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader > div > svg {
  position: relative;
  right: 15px;
  bottom: 15px;
}

.loader > p.loader-txt {
  position: absolute;
  top: 18px;
  left: 10px;
  text-align: center;
  width: 38px;
  font-size: 14px;
}