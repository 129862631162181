.ReactVirtualized__Table {
   display: inline-block;
   /* border: 2px solid rgba(0, 0, 0, 0.1); */
   background-color: #e0e0e0;
   user-select: none;
}

.ReactVirtualized__Table__headerColumn {
   display: flex;
   flex-direction: row;
   justify-content: center;
   padding: 0;
   outline: none;
}

.ReactVirtualized__Table__headerTruncatedText {
   /* display: flex;
   flex-direction: row; */
   flex: auto;
}

.ReactVirtualized__Table__headerTruncatedText p {
   margin: 0;
   flex-shrink: 1;
}

.triangle-sort-icon {
   width: 8px;
   flex-shrink: 0;
   margin-left: 2px;
   margin-right: 2px;
}

.triangle-sort-icon.rotated {
   transform: rotate(180deg);
}

.DragHandle {
   flex: 0 0 16px;
   z-index: 2;
   cursor: col-resize;
   color: #989898;
}
.DragHandle:hover {
   background-color: rgba(0, 0, 0, 0.1);
}

.DragHandleActive,
.DragHandleActive:hover {
   color: #989898;
   z-index: 3;
}

.DragHandleIcon {
   flex: 0 0 12px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}


.headerRow {
   background-color: #000F1A;
   color: #fff;
   padding-bottom: 3px;
   /* margin-top: -2px; */
}

.evenRow:focus, .oddRow:focus {
   outline: none;
}
.evenRow:hover, .oddRow:hover {
   cursor: pointer;
   outline: none;
}

.oddRow {
   background-color: #fafafa;
}
.evenRow {
   background-color: #dfdfdf;
}

.evenRow:not(.selectedRow):hover,
.oddRow:not(.selectedRow):hover {
   background-color: rgb(202, 228, 245);
}
.evenRow.selectedRow,
.oddRow.selectedRow {
   background-color: rgb(85, 171, 224);
}

/* .evenRow.errorRow {
   background-color: #E1AFAF;
} */
.evenRow.errorRow,
.oddRow.errorRow {
   background-color: rgb(238, 196, 196);
}
.evenRow.errorRow:not(.selectedRow):hover,
.oddRow.errorRow:not(.selectedRow):hover {
   background-color: #DA9A9A;
}

.errorRow.selectedRow {
   background-color: #d38282;
}


/* .evenRow.warningRow {
   background-color: #eed774;
} */
.warningRow {
   background-color: #f3dd8d;
}
.evenRow.warningRow:not(.selectedRow):hover,
.oddRow.warningRow:not(.selectedRow):hover {
   background-color: #eed255;
}
.evenRow.warningRow.selectedRow,
.oddRow.warningRow.selectedRow {
   background-color: #e9c656;
}


.bottom-table-header {
   font-size: 14px;
   font-weight: 400;
   /* text-decoration: none; */
   text-transform: none;
}
