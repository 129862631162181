.timeframe-buttons {

   margin-top: -2px;
   padding: 0px 18px 0 12px;

   &__group-title {
      font-size: 0.8rem;
      margin-left: 10px;
      margin-right: 4px;
      margin-bottom: 4px;
      color: white;
      display: inline-block;
   }

   &__button {
      display: inline-block;
      padding: 0 6px;
      margin: 0 2px;
      text-align: center;
      font-size: 0.75rem;
      color: white;
      border: 1px solid #0075BE;
      cursor: pointer;

      &--action {
         &:hover { background: #0075BE22; }
         &:active { background: #0075BE; }
      }
   }

}
