.bottom-menu {
   width: 100%;
   box-sizing: border-box;
   display: block;
   position: relative;
   background-color: #000F1A;
   flex-shrink: 0;
   box-shadow: rgba(0, 0, 0, 0.17) 0px 0px 3px 1px;
}

.bottom-menu-handle {
   width: 100%;
   height: 4px;
   background-color: #000F1A;
   cursor: ns-resize;
   border-top: solid 1px #9e9e9e;
}

.bottom-menu-buttons-row {
   position: absolute;
   top: -26px;
   right: 4px;
   display: flex;
   flex-direction: row;
}

.bottom-menu button.button.clear-selection-button {
   height: 24px;
   background-color: rgba(0, 15, 26, 0.8);
   border-radius: 12px;
   text-align: center;
   margin-right: 8px;
   padding: 0 12px 0 12px;
}

.bottom-menu button.button.toggle-height {
   width: 24px;
   height: 24px;
   background-color: rgba(0, 15, 26, 0.8);
   border-radius: 50%;

   display: flex;
   align-items: center;
   justify-content: center;
}

.bottom-menu button.button.toggle-height img {
   width: 20px;
   height: 20px;
   margin-bottom: 2px;
   transform: rotate(90deg);
}
.bottom-menu button.button.toggle-height.down img {
   margin-top: 2px;
   transform: rotate(270deg);
}
