
.analysis-card {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   user-select: none;
}

.analysis-card .box {
   padding-left: 12px;
   padding-right: 12px;
   position: relative;
}

.analysis-card .image-row {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   height: 200px;
}

.analysis-card .image-row .image-frame {
   padding-right: 12px;
   height: inherit;
}


.analysis-card .result-box .result-box-inner-container,
.analysis-card-mobile .result-container {
   height: 100%;
   display: flex;
}


.analysis-card p {
   margin: 0;
}

.analysis-card p.single-line {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   width:100%;
}

.info-container h4 {
   margin-bottom: 0;
   margin-right: 8px;
}

.error-message-title {
   color: #be0000;
   font-weight: 400;
   padding-right: 8px;
}

.chart-container {
   max-width: 300px;
}


.analysis-card button.button.info-box-show-btn {
   color: #0075BE;
   margin: 0;
   padding-left: 0;
   padding-bottom: 0;
   padding-top: 4px;

}


/*MOBILE*/

.analysis-card-mobile {
   padding-top: 20px;
}
.analysis-card-mobile .info-container p {
   margin-bottom: 4px;
}
.analysis-card-mobile p {
   margin: 0;
}




















/* New Structure */

.info-box {
   display: flex;
   width: 100%;
   /* border-bottom: solid 1px #c2c2c2; */
   padding-bottom: 2px;
   /* margin-bottom: 8px; */
}

.info-item-row {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   margin-bottom: -18px;
}

.info-item-box {
   min-width: 180px;
   padding-right: 24px;
   margin-bottom: 12px;
}

.info-item-box h4 {
   margin-bottom: 4px
}

.analysis-card-item-container {
   width: 100%;
}

.result-box {
   display: flex;
   flex-direction: column;
   width: 50%;
}

.images-box {
   width: 50%;
}

.items-container {
   display: flex;
   flex-grow: 1,
}



.mobile-img-container {
   margin-top: 24px;
   width: 100%;
}

.mobile-img-container img {
   width: 100%;
}
