.tool-card {
   text-align: center;
   padding-bottom: 60px;
   padding-top: 30px;
   margin-right: 38px;
}

.card-btn-container {
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
}

.tool-card h3 {
   margin-bottom: 0;
   color: #0075BE;
}

.tool-card-content {
   padding-top: 70px;
   background-color: #fff;
   width: 230px;
   height: 290px;
   position: relative;
   outline: 1px solid rgba(0, 0, 0, 0.012);
   box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
}

p.tool-level {
   font-size: 12px;
   color: #989898;
   margin-bottom: 0;
   margin-top: -4px;
}

.tool-card img.lock, .tool-card-mobile img.lock {
   height: 30px;
   width: auto;
}

p.tool-description {
   padding: 0 6px;
   margin-top: 6px;
}

p.tool-analyses-status {
   font-size: 12px;
   /* margin-top: 0px; */
   margin-bottom: 0px;
   margin-top: -6px;
   color: #0075BE;
}

.blue-bottom-border {
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   height: 6px;
   background-color: #0075BE;
}

.card-img-container {
   height: 96px;
   width: 96px;
   border-radius: 50%;
   /* background-color: #040507; */
   background-image: linear-gradient(to bottom right, #0075BE, #000);
   /* background: url("../../assets/header.png");
   background-position: bottom;
   background-repeat: none;
   background-size: cover; */
   background-color: #0075BE;
   position: absolute;
   top: -34px;
   left: calc(50% - 48px);
   display: flex;
   align-items: center;
   justify-content: center;
}

.card-img-container p {
   color: #fff;
   font-weight: 300;
   font-size: 30px;
   margin: 0;
}




/*MOBILE*/
.tool-card-mobile {
   padding: 12px 0;
   display: flex;
   width: 100%;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
   position: relative;
}
.tool-card-mobile h3 {
   color: #0075BE;
   margin-bottom: 2px;
}

.card-img-mobile {
   flex-shrink: 0;
   height: 80px;
   width: 80px;
   border-radius: 50%;
   background-image: linear-gradient(to bottom right, #0075BE, #000);
   display: flex;
   align-items: center;
   justify-content: center;
   color: white;
}

.card-img-mobile p {
   color: #fff;
   font-weight: 300;
   font-size: 26px;
   margin: 0;
}

.tool-card-txt-mobile {
   padding-left: 12px;
}

.cell-separator {
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   height: 1px;
   background-color: #0075BE;
}
p.tool-desc-mobile {
   text-align: left;
   margin: 0;
   color: #333;
}
