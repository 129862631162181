button.dropdown-button {
   border: none;
   color: #fff;
   background: transparent;
   font-weight: 400;
   font-size: 16px;
   padding: 6px;
   display: flex;
   flex-direction: row;
   align-items: center;
}

button.dropdown-button div.dropdown-button-titles p {
   margin: 0;
}

button.dropdown-button>div {
   text-align: right;
}

button.dropdown-button>div p.subtitle{
   font-weight: 300;
   font-size: 14px;
   margin-top: -4px;
}

button.dropdown-button.black {
   color: #333;
}

button.dropdown-button:hover {
   cursor: pointer;
}

i.arrow-down {
   border: solid #fff;
   border-width: 0 2px 2px 0;
   display: inline-block;
   padding: 3px;
   transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
   margin-left: 16px;
   margin-bottom: 3px;
}

.dropdown-button.black i.arrow-down {
   border: solid #333;
   border-width: 0 2px 2px 0;
}
