.pie-chart-container {
   display: flex;
   align-items: center;
   height: 100%;
   max-height: 200px;
   overflow: hidden;
}

.pie-chart-container[data--is-mobile=true] {
   flex-grow: 1;
   max-width: 412px;
   margin: 8px;
}

/* ---- LEGENDS ---- */
.pie-chart-container .pie-chart {
   float: left;
   width: 60%;
}
.pie-chart-container .legends {
   float: left;
   min-width: 120px;
}

/* Desktop scaling */
.pie-chart-container .pie-chart {
   width: 50%;
}
.pie-chart-container .legends {
   width: 50%;
   min-height: 112px;
}

@media only screen and (max-width: 1400px) {
   .pie-chart-container[data--is-mobile=false] .pie-chart { width: 50%; }
   .pie-chart-container[data--is-mobile=false] .legends {    width: 50%; }
}

/* Mobile scaling  */
.pie-chart-container[data--is-mobile=true] .pie-chart { width: 60%; }
.pie-chart-container[data--is-mobile=true] .legends { width: 40%; }

/* Styling */
.pie-chart-container .legend .legend-dot {
   height: 13px;
   width: 13px;
   border-radius: 50%;
   border: 1px solid;
   flex-shrink: 0;
}

.pie-chart-container .legend {
   display: flex;
}

.pie-chart-container .legend p {
   font-size: 0.8rem;
   margin-left: 6px;
   position: relative;
   bottom: 2px;
   display: inline-block;
}
