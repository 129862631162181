.tgl {
   display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
   box-sizing: border-box;
}
.tgl::-moz-selection, .tgl:after::-moz-selection, .tgl:before::-moz-selection, .tgl *::-moz-selection, .tgl *:after::-moz-selection, .tgl *:before::-moz-selection, .tgl + .tgl-btn::-moz-selection {
   background: none;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
   background: none;
}
.tgl + .tgl-btn {
   outline: 0;
   display: block;
   /* width: 4em;
   height: 2em; */
   width: 38px;
   height: 19px;
   position: relative;
   cursor: pointer;
   -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
         user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
   position: relative;
   display: block;
   content: "";
   width: 50%;
   height: 100%;
}
.tgl + .tgl-btn:after {
   left: 0;
}
.tgl + .tgl-btn:before {
   display: none;
}
.tgl:checked + .tgl-btn:after {
   left: 50%;
}

.tgl-light + .tgl-btn {
   background: #989898;
   border-radius: 19px; /* === height*/
   padding: 1px;
   transition: all 0.4s ease;
}
.tgl-light + .tgl-btn:after {
   border-radius: 50%;
   background: #fff;
   transition: all 0.2s ease;
}
.tgl-light:checked + .tgl-btn {
   background: #0075BE;
}

.tgl-light[disabled] + .tgl-btn {
   background: #989898;
   pointer-events: none;
}
.tgl-light[disabled] + .tgl-btn:after {
   background: #ddd;
}

.tgl-btn {
   margin: 0;
}
