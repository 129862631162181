.main-modal-component {
   position: fixed;
   bottom: 0;
   top: 0;
   left: 0;
   right: 0;
   background-color: rgba(0, 0, 0, 0.7);
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 1000;
}


.main-modal {
   display: flex;
   flex-direction: column;
   background-color: #fff;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
   min-width: 300px;
   max-width: calc(100vw - 24px);
   max-height: calc(100vh - 20px);
   user-select: none;

   &.mobile {
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      max-height: 100vh;
   }

   /* ---- HEADER ---- */
   &__header {
      /* background-color: #000F1A;
      color: #fff; */
      height: 56px;
      padding: 0 12px;
      position: relative;
      border-bottom: solid 1px #0075BE;
      margin: 0 12px;
      display: flex;
      align-items: center;

      &__close-btn {
         position: absolute;
         right: 6px;
         top: 12px;

         img {
            height: 22px;
            width: 22px;
            margin-top: 3px;
         }
      }

      h3 {
         margin: 0;
         color: #0075BE;
         /* font-weight: 300; */
      }
   }
   &.mobile &__header {
      z-index: 1001;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
   }


   /* ---- CONTENT CONTAINER ---- */
   &__content-container {
      padding: 24px;
      padding-bottom: 12px;
      flex: 1;
      max-height: calc(100vh - 184px);
      overflow-y: auto;
   }
   &.mobile &__content-container {
      flex: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
   }

   /* ---- FOOTER ---- */
   &__footer {
      margin: 0 10px;

      p {
         font-size: 15px;
         color: #989898;
         padding-left: 12px;
         margin-bottom: 0;
         display: inline-block;

         &.error-txt {
            color: #bf1a1ac2;
            font-weight: 400;
         }
      }

      &__line {
         width: 100%;
         height: 1px;
         background-color: #989898;
         margin-bottom: 16px;
      }

      &__right-btns {
         float: right;
         margin-left: auto;
      }
      &__cancel-btn {
         margin-right: 16px;
      }
   }

   &.mobile
   &__footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
   }
}


@media only screen and (max-width: 575.98px) {
   .main-modal__footer__right-btns {
      display: flex;
      width: 100%;
   }

   .main-modal__footer button.button {
      width: 100%;
   }
}
