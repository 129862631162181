@import '../../shared';

.side-navigation-menu {
   background-color: $color_dark_blue;
   color: #fafafa;
   width: 236px;
   height: calc(100vh - #{$navbar_height});
   border-top: solid rgba(255, 255, 255, 0.1) 1px;
   border-right: solid rgba(255, 255, 255, 0.1) 1px;
   // padding-top: 24px;

   .side-menu-button {
      border: none;
      outline: none;
      color: #fafafa;
      width: 100%;
      background: none;
      text-align: left;
      padding: 13px;
      padding-top: 15px;
      padding-left: 24px;
      // border-top: solid rgba(255, 255, 255, 0.1) 1px;
      // border-bottom: solid rgba(255, 255, 255, 0.1) 1px;

      &:hover:enabled {
         color: $color_blue;
      }

      &.selected {
         //border-bottom: 2px solid $color_blue;
         background-color: $color_blue;
         &:hover {
            color: #fafafa;
         }
      }

      &:disabled {
         opacity: 0.5;
      }
   }

}
