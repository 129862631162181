button.button {
   width: 120px;
   height: 34px;
   color: #fff;
   border: none;
   background: transparent;
   margin-bottom: 16px;
   padding: 0;
   font-weight: 300;
}

@media only screen and (max-width: 575.98px) {
   .button.button:not(.icon) {
      height: 38px;
   }
}


button.button:hover {
   cursor: pointer;
}

button.button[disabled] {
   opacity: 0.6;
   pointer-events: none;
}

button.button.primary {
   background-color: #0075BE;
}
button.button.danger {
   background-color: #be0000;
}

button.button.primary:hover,
button.button.danger:hover {
   opacity: 0.8;
}

button.outlined {
   border-width: 1px;
   border-style: solid;
   border-color: #0075BE;
   color: #0075BE;
}

button.outlined:hover {
   border-width: 1px;
   border-style: solid;
   border-color: #0075BE;
   background-color: #0075BE;
   color: #fff;
   opacity: 0.8;
}

button.icon {
   height: 28px;
   width: 28px;
   max-width: 100%;
   max-height: 100%;
   background-color: transparent;
}
button.icon:hover {
   opacity: 0.6;
}
img.button-icon {
   height: 100%;
   background-color: transparent;
   border: none;
   padding: 4px;
}


button.text {
   background-color: transparent;
   border: none;
   width: auto;
   height: auto;
   padding: 8px;
   margin: 0;
}
button.text:hover {
   opacity: 0.8;
}
