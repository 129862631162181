// Variables
$color_blue: #0075BE;
$color_error_txt: #be0000;
$color_dark_blue: #000F1A;

// Navbar
$navbar_height: 60px;


// Side-menu
$side_menu_width: 236px;


:export {
   sideMenuWidth: $side_menu_width;
   navbarHeight: $navbar_height;
}
