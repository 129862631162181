.latest-images-card {

   margin: 0 -8px;
   display: flex;
   height: 240px;

   &.is-full-screen {
      height: calc(100vh - 350px); // Would be better if calculates automatically
      max-width: 100%;
      overflow-x: hidden;
   }

   .image-container {
      display: flex;
   }

   .image-container > .image-holder {
      margin: auto;
      height: 100%;
      width: 100%;
      text-align: center;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition-duration: 0.3s;
   }

   .image-container > .image-holder:not(.image-holder--is-loaded) {
      opacity: 0;
   }
}
