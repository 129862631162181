.mobile-site-selector {
   position: relative;
   margin-bottom: 30px;
}

.mobile-site-selector h3 {
   color: #0075BE;
   margin-bottom: 12px;
}

.mobile-site-selector h2 {
   margin-bottom: 16px;
}
.cell-separator {
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   height: 1px;
   background-color: #0075BE;
}

.mobile-site-selector button {
   outline: none;
   border: none;
   background: none;
}
.mobile-site-selector button:hover {
   cursor: pointer;
}

.mobile-site-selector .dropdown {
   display:inline-block;
}

select {
   position:absolute;
   -webkit-appearance: menulist-button;
   width:100%;
   height:100%;
   opacity:0.001;
   z-index:100;
}

.mobile-site-selector .arrow-down {
   width: 0;
   height: 0;
   border-left: 6px solid transparent;
   border-right: 6px solid transparent;
   border-top: 6px solid #0075BE;
   margin-left: 8px;
   margin-bottom: 12px;
}


.mobile-site-selector .flex-button {
   display: flex;
   align-items: center;
}
