.analysis-position .image-box:not(:hover) .btn-keep:not(.btn-keep--is-kept) {
   opacity: 0;
}

.analysis-position .btn-keep {
   transition: opacity 0.14s ease-in-out;
   position: absolute;
   margin-left: 4px;
   margin-top: 6px;
   z-index: 1;
}

.analysis-position .image-container {
   background-color: black;
}

.analysis-position.image-holder {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
}

.image-holder__image-thumbnail {
   object-fit: contain;
   width: 100%;
   height: 100%;
}

.analysis-position .image-container > .image-holder {
   margin: auto;
   text-align: center;
   background-repeat: no-repeat;
   background-position: center;
   background-size: contain;
   transition-duration: 0.3s;
}

.analysis-position .image-container > .image-holder:not(.image-holder--is-loaded) {
   opacity: 0;
}
