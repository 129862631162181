.toolbox h2 {
   color: #989898;
   margin-bottom: 12px;
}

.toolbox-title-row {
   display: flex;
}

.toolbox-row {
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-start;
   margin-right: -38px;
}

.toolbox .unit-section {
   border-bottom: solid #e1e1e1 1px;
}

/* .toolbox .unit-section:hover {
   border-bottom: solid #0075be 1px;
} */

.toolbox .unit-section p {
   margin: 0;
}
.toolbox .unit-section p.title {
   margin-right: 8px;
}

.unit-section .unit-section-row {
   display: flex;
   align-items: center;
   position: relative;
}

.unit-section-menu-dropdown {
   position: absolute;
   top: 4px;
   right: 4px;
}

.toolbox .input.edit-unit-input {
   margin-bottom: 12px;
}

.toolbox button.button.save-button {
   margin-left: 12px;
}

/* .toolbox-modal-content .flex-row {
   display: flex;
} */

button.add-new-unit {
   border-radius: 50%;
   background-color: #0075be;
   width: 24px;
   height: 24px;
   outline: none;
   border: none;
   color: white;
   font-weight: 300;
   padding: 0;
   margin-top: -2px;
   margin-left: 12px;
   margin-bottom: 16px;
}


button.add-new-unit:hover {
   cursor: pointer;
   opacity: 0.8;
}

button.add-new-unit img {
   height: 12px;
   width: auto;
}

button.button.toolbox-icon-btn {
   opacity: 0.5;
}
button.button.toolbox-icon-btn:hover {
   opacity: 0.4;
}


@media only screen and (max-width: 767px) {
   .toolbox {
      margin-bottom: 30px;
   }
   .toolbox-row {
      margin-right: 0;
   }
}

/* .toolbox {
   width: 1340px;
   max-width: 100%;
   background-color: red;
}




@media only screen and (max-width: 1630px) {
   .toolbox {
      width: 1072px;
      background-color: blue;
   }
}

@media only screen and (max-width: 1362px) {
   .toolbox {
      width: 804px;
      background-color: aqua;
   }
} */
