.content-container {
   position: relative;
   background-color: #F0F0F0;
   padding: 12px 16px;
   overflow: auto;
   height: 100%;
}

.content-container .inner-container {
   margin: auto;
   display: block;
   width: 100%;
   max-width: 1800px;
   margin-bottom: 12px;
}

.content-container .section-title:first-of-type {
   margin-top: 8px;
}

@media only screen and (min-width: 1520px) {
   .content-container {
      padding: 12px 32px;
   }
}

@media only screen and (min-width: 1720px) {
   .content-container {
      padding: 12px 48px;
   }
}
