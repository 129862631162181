@import '../../../shared';

.image-settings-section {

   padding: 6px 24px;
   overflow: auto;

   p {
      margin-bottom: 0;
   }

   h3 {
      color: $color_blue;
      margin-bottom: 0;
   }

   h4 {
      margin-top: 18px;
   }

   &__config-row {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 400px;
      max-width: 100%;
      margin-bottom: 8px;

      input.input {
         width: 80px;
         /*text-align: center;*/
      }
   }

   &__btn-save {
      margin-left: 24px;
   }

   &__error-txt {
      color: $color_error_txt;
      padding-top: 24px;
   }
}
