.custom-dropdown {
   flex-shrink: 0;
}

.custom-dropdown > .custom-dropdown-menu > .dropdown-item {
   cursor: pointer;
   user-select: none;
}

.custom-dropdown-menu {
   position: absolute;
   z-index: 1000;
   /* transform: translateX(-280px); */
   width: max-content;
   padding: .5rem 0;
   margin: .125rem 0 0;
   font-size: 1rem;
   text-align: left;
   list-style: none;
   background-color: #fff;
   border: 1px solid rgba(0,0,0,.15);
}

.custom-dropdown-menu:not([data-dropdown-visible='true']) {
   display: none;
}

.custom-dropdown-menu p {
   font-weight: 300;
   color: #333333;
   margin: 0;
}

p.custom-dropdown-item {
   z-index: 1040;
}

.custom-dropdown .custom-dropdown-item.active,
.custom-dropdown .custom-dropdown-item:active {
   background: rgb(233, 233, 233);
}

.custom-dropdown .custom-dropdown-item {
   display: block;
   width: 100%;
   padding: .25rem 1.5rem;
   clear: both;
   text-align: inherit;
   /* white-space: nowrap; */
   /* word-wrap: break-word; */
   background-color: transparent;
   border: 0;
}

.custom-dropdown .custom-dropdown-item:hover {
   background: rgb(202, 228, 245);
}

.custom-dropdown .custom-dropdown-item.selected:not(.disabled):not(hover) {
   background-color: rgb(85, 171, 224);
}

.custom-dropdown .custom-dropdown-item.disabled {
   color: #989898;
}
.custom-dropdown .custom-dropdown-item.disabled:hover {
   background-color: transparent;
   cursor: default;
   color: #989898;
}

.custom-dropdown .custom-dropdown-item[data-type=danger] {
   color: #be0000;
}
.custom-dropdown .custom-dropdown-item[data-type=danger]:hover {
   color: #be0000;
   background: rgb(245, 202, 202);
}

/* .custom-dropdown .custom-dropdown-item[data-type=danger].active,
.custom-dropdown .custom-dropdown-item[data-type=danger]:active {
   background: #be0000;
   color: white;
} */


p.dropdown-section-title {
   font-size: 14px;
   font-weight: 400;
   color: #989898;
   padding-left: 16px;
   padding-right: 16px;
   margin-bottom: -6px;
   margin-top: 4px;
}
