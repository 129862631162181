.secondary-navbar {
   background-color: #000F1A;
   display: flex;
   flex-direction: row;
   align-items: center;
}

.secondary-navbar h3 {
   color: #fff;
   margin-bottom: -3px;
   margin-left: 18px;
   margin-right: 52px;
}

.secondary-navbar button.button {
   margin: 0;
   margin-left: 8px;
}
