input.input {
   width: 100%;
   color: #333;
   font-weight: 300;
   font-size: 16px;
   padding-left: 6px;
   padding-right: 6px;
   border-width: 1px;
   border-style: solid;
   outline: none;
   height: 30px;
}

input.input:not(:disabled) {
   border-color: #989898;
}
input.input:disabled {
   background-color: #F4F4F4;
}

input.input:focus {
   border: solid 1px #0075BE;
   outline: none;
   font-size: 16px;
}

input.input:disabled {
   color: #989898;
   user-select: none;
}
