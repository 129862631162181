div.login-box {
   width: 400px;
   max-width: 100%;
   background-color: #000f1a;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   color: #fff;
   padding: 20px 40px;
   position: relative;
   box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.3);

   background: url("../../../assets/headerFullHd.jpg");
   /* background-position: 80% 45%; */
   background-size: cover;
   background-repeat: no-repeat;
}

div.login-box .input-container {
   width: 300px;
}

p.login-error-message {
   margin-top: 14px;
   height: 20px;
}

div.login-box h2 {
   margin-top: 56px;
   margin-bottom: 30px;
}

div.login-box input {
   border: none;
   margin-bottom: 20px;
   height: 34px;
}

div.login-box input.input:focus {
   border: none;
}

div.login-box input::placeholder {
   color: #989898;
}


.fingerprint-icon-box {
   height: 68px;
   width: 68px;
   border-radius: 50%;
   position: absolute;
   top: -32px;
   left: calc(50% - 34px);
   background-color: #000f1a;
   display: flex;
   justify-content: center;
   align-items: center;
}

.fingerprint-icon-box img {
   height: 46px;
}

button.button.login-button {
   width: 256px;
   height: 42px;
   font-weight: 400;
   margin-top: 12px;
   background-color: #000000aa;
   color: #fff;
   margin-bottom: 0px;
}

button.button.login-button--local {
   background-color: #fff;
   height: 34px;
   max-width: 120px;
   margin-bottom: 24px;
   color: #000;
}


@media only screen and (max-width: 575.98px) {
   div.login-box {
      min-height: 100%;
      width: 100%;
      box-shadow: none;
      overflow: hidden;
      padding: 20px;
   }

   div.login-box h2 {
      margin-top: 30px;
   }

   .fingerprint-icon-box {
      position: static;
   }
}
