button.clock-button {

   background: none;
   color: #fafafa;
   outline: none;
   border: none;
   margin-right: 16px;


   p {
      margin-bottom: -4px;
   }

   p.subtitle {
      font-weight: 300;
      font-size: 15px;
      margin: 0;
   }

   .inner-row {
      display: flex;
      align-items: center;
      margin-bottom: -1px;
   }

   img {
      height: 15px;
      padding-right: 8px;
   }

   &:focus {
      outline: none;
   }

}
