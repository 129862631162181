.media-viewer {
   position: fixed;
   bottom: 0;
   top: 0;
   left: 0;
   right: 0;
   background-color: rgba(15, 15, 15, 0.99);
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 2000;
}

.media-viewer img.center-media-view {
   height: auto;
   width: auto;
   max-width: calc(100% - 24px);
   max-height: calc(100% - 24px);
   /* outline: solid 1px #fff; */
}

.media-viewer button.btn-keep {
   position: absolute;
   top: 8px;
   left: 8px;
   width: 30px;
   height: 30px;
}

.media-viewer button.button.close-viewer {
   position: absolute;
   top: 9px;
   right: 8px;
   width: 26px;
   height: 26px;
}

.media-viewer a.download-file {
   position: absolute;
   top: 10px;
   right: 48px;
}

.media-viewer a.download-file:hover {
   cursor: pointer;
   opacity: 0.6;
}

.media-viewer a.download-file img {
   height: 23px;
   width: 23px;
}
