footer {
   display: flex;
   align-items: center;
   align-content: center;
   background-color: #eff0ef;
   justify-content: space-between;
   height: 40px;
   padding-left: 24px;
}

footer.default {
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   border-top: solid #e0e1e0 1px;
}

footer>p {
   margin: 0;
   margin-top: 8px;
   font-size: 12px;
   font-weight: 400;
   color: #989898;
}

footer .logo-container {
   color: #0075BE;
   background-color: #e0e1e0;
   display: flex;
   flex-direction: row;
   align-items: center;
   position: relative;
}

footer .logo-container p {
   margin: 0;
   font-size: 11px;
   font-weight: 300;
   flex-shrink: 0;
   /* padding-bottom: 16px; */
}

.left-side-angle {
   height: 0;
   width: 8px;
   border-bottom: 40px solid #e0e1e0;
   border-left: 20px solid #eff0ef;
   flex-shrink: 0;
}

footer .logo-container img {
   height: 18px;
   margin-right: 20px;
   margin-left: 6px;
   flex-shrink: 0;
   /* p-bottom: 16px; */
}

footer .iiot-row {
   display: flex;
   flex-direction: row;
   align-items: baseline;
   margin-top: 8px;
}





/*Small Footer*/
footer.small {
   height: 30px;
   background-color: #000F1A;
   border-top: solid rgba(255, 255, 255, 0.1) 1px;
}

footer.small>p {
   margin: 0;
}

footer .small-logo-container {
   display: flex;
   align-items: center;
}

footer .small-logo-container img {
   height: 16px;
   margin-right: 24px;
   margin-left: 8px;
}
