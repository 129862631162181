@import '../../../shared';

.page-statistics {

   .activity-table {
      border-collapse: collapse;
      width: 100%;

      td, th {
         border: 1px solid #ddd;
         padding: 8px 16px;
      }

      tr:nth-child(even){background-color: #f2f2f2;}
      // tr:hover {background-color: #ddd;}

      th {
         padding-top: 12px;
         padding-bottom: 12px;
         text-align: left;
         background-color: $color_blue;
         color: white;
       }

    }

}
