div.navbar {
   width: 100%;
   height: 60px;
   position: relative;
   display: flex;
   flex-wrap: wrap; /* allow us to do the line break for collapsing content */
   align-items: center;
   justify-content: space-between; /* space out brand from logo */
   padding: 0.5rem 1rem;
   background-color: #000; /*#000F1A;*/
   align-content: center;
   flex-shrink: 0;
   z-index: 8;
   box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.18);
   user-select: none;
}

div.navbar.transparent-navbar {
   background-color: transparent;
}

.navbar img.navbar-bg-img {
   position: absolute;
   right: 0;
   bottom: 0;
   width: auto;
   height: 100%;
   opacity: 0.7;
}

div.nav-items-group {
   display: flex;
   align-items: center;
   z-index: 1;
}

.navbar img.metris-ava-logo {
   height: 18px;
}
.navbar img.metris-logo {
   height: 52px;
   margin-bottom: 10px;
   margin-left: 24px;
   margin-right: -8px;
}

.home-link {
   margin-right: 30px;
}

.home-link:hover {
   cursor: pointer;
}

.cogwheel-dropdown {
   margin-left: 24px;
   margin-right: 6px;
   margin-bottom: 2px;
   height: 26px;
   width: 26px;
}
