/* BLUE COLOR CODE #0075BE */

.app {
   height: 100vh;
   flex-direction: column;
   user-select: none;
}

/* general classes g- + className */
.g-container {
   max-width: 1630px;/*1570px;*/
   padding: 0 30px;
   margin: auto;
   display: block
}

.selectable-image:hover {
   cursor: pointer;
   opacity: 0.9;
}

.flex-row {
   display: flex;
   align-items: center;
}
/* .flex-row p {
   margin: 0;
} */


a {
   color: #0075BE;
}

a:hover {
   text-decoration: none;
}

@media only screen and (max-width: 767px) {
   .g-container {
      padding: 0 6px;
   }
}


/* Text area */
textarea {
   border: solid 1px #989898;
   padding: 4px 8px;
   background-color: transparent;
   font-weight: 300;
   font-size: 16px;
   width: 100%;
   outline: 0;
   min-height: 56px;
}

textarea:focus {
   outline: none !important;
   border:1px solid #0075BE;
}
