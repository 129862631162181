.mobile-data-list {
   position: relative;
   padding-bottom: 42px; /*Mobile menu height*/
}

/* .mobile-data-list .list-container {
   overflow: scroll;
   -webkit-overflow-scrolling: touch;
   height: calc(100% - 30px)
} */

.list-container {
   padding-top: 34px;
}

.title-cell {
   display: flex;
   flex-direction: row;
   justify-content: center;
   background-color: #000f1a;
   color: #fafafa;
   font-weight: 400;
   padding: 4px 12px;
   height: 34px;
   z-index: 100;
   position: fixed;
   top: 60px; /*nav bar height*/
   left: 0;
   right: 0;
}

.data-cell {
   display: flex;
   flex-direction: row;
   justify-content: center;
   padding: 12px 12px;
   border-bottom: solid 1px #989898;
   background-color: #fcfcfc;
}

.data-cell.darker {
   background-color: #e1e1e1;
}

.data-cell:active, .data-cell.highlighted {
   background-color: rgb(202, 228, 245);
}
.data-cell--error {
   background-color: #E1AFAF;
   border-bottom: solid 1px rgb(148, 114, 114);
}
.data-cell--error:active, .data-cell--error.highlighted {
   background-color: #DA9A9A;
}
.data-cell--warning {
   background-color: #eed774;
   border-bottom: solid 1px #9b8a3f;
}
.data-cell--warning:active {
   background-color: #eed255;
}

/* .data-cell:focus {
   cursor: pointer;
   background-color: #989898;
} */

.title-cell p, .data-cell p {
   margin: 0;
}
.title-cell>div:hover {
   cursor: pointer;
}
.title-cell p {
   padding-right: 8px
}

.first {
   width: 50%;
}
.second {
   width: 50%;
}
/* .third {
   width: 30%;
} */


.triangle-sort-icon {
   width: 8px;
   flex-shrink: 0;
   margin-left: 2px;
   margin-right: 2px;
}

.triangle-sort-icon.rotated {
   transform: rotate(180deg);
}
