@import '../../../shared';

.dcs-tags-position-container {

   overflow-y: auto;
   overflow-x: auto;
   margin-bottom: 20px;

   h3.position-label {
      color: $color_blue;
      width: 180px; // same as p.element-name for correct positioning
      margin-right: 8px;
   }

   p {
      width: 200px;
      margin-bottom: 0;
   }

   &__elements {
      display: flex;
      align-items: center;
      padding-bottom: 4px;
      margin-bottom: 4px;
      border-bottom: solid 1px #e0e0e0;

      p.element-name {
         width: 180px;
         margin-right: 8px;
      }
   }

   input.input:disabled {
      color: #333;
   }

}
