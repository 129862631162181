@import '../../../shared';

/* DESKTOP */

.side-menu {
   background-color: #000F1A;
   color: #fff;
   width: $side_menu_width;
   min-height: 10px;
   height: 100%;
   padding: 12px;
   overflow: auto;
   flex-shrink: 0;
   border-top: solid rgba(255, 255, 255, 0.1) 1px;
   border-right: solid rgba(255, 255, 255, 0.1) 1px;
   z-index: 3;
   box-shadow: -1px 0 2px 2px rgba(0, 0, 0, 0.6);
   user-select: none;
}



/* MOBILE AND DESKTOP */

.side-menu,
.mobile-menu {

   .time-frame {
      border: solid 1px #0075BE;
      display: flex;
      height: 34px;
      align-items: center;
      position: relative;
      overflow: hidden;
      margin-bottom: 16px;
   }

   .side-menu-line {
      height: 1px;
      background-color: #989898;
      width: 100%;
      margin-bottom: 24px;
   }

   .side-menu-title {
      color: #989898;
      margin-bottom: 8px;
   }


   .data-info {
      background-color: #040507;
      margin-left: -12px;
      margin-right: -12px;
      margin-bottom: 40px;
      margin-top: 12px;
      padding: 12px;
      min-height: 100px;

      border-bottom: solid rgba(255, 255, 255, 0.2) 1px;
      border-top: solid rgba(255, 255, 255, 0.2) 1px;
      /* position: relative; */
   }

   .data-info p {
      margin: 0;
   }

   .data-info p.title {
      font-weight: 400;
      color: #0075BE;
      margin-bottom: 4px;
   }
   /* .data-info p.list-item {
      margin-left: 16px;
      margin-bottom: 4px;
   } */

   .data-info p.placeholder {
      /* color: #989898; */
      opacity: 0.5;
      width: 100%;
      text-align: center;
      padding-top: 26px;
   }

   .data-info .selected-units, .data-info .selected-tags {
      margin-bottom: 12px;
   }


   .data-info .data-actions-btn {
      margin-top: 24px;
   }

   .time-frame p {
      width: 50%;
      text-align: center;
      margin: 0;
   }

   .time-frame .arrow {
      position: absolute;
      left: calc(50% - 24px);
      /* background-color: red; */
      border-top: solid 1px #0075BE;
      border-right: solid 1px #0075BE;
      height: 24px;
      width: 27px;
      top: 3px;
      transform: rotate(45deg);
      /* z-index: 0; */
   }

   .first-menu-item {
      margin-top: -8px;
   }


   .tgl-btn-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 2px;
      padding-right: 0;
      padding-bottom: 6px;
   }

   .tgl-btn-row p {
      margin: 0;
      margin-top: 4px;
   }


   .collapse-section {
      cursor: pointer;
   }

   .collapse-section > button {
      background: none;
      border: none;
      margin-top: -4px;
      padding-left: 20px;
      padding-bottom: 8px;
      height: 33px;
   }

   .collapse-section:hover > button {
      opacity: 0.8;
   }

   button.button.data-action-btn {
      width: 100%;
      margin-bottom: 0;
   }

   button.button.side-menu-btn-large {
      border-width: 2px;
      width: 100%;
      height: 36px;
      font-weight: 300;
      color: #fff;
   }

   .toggle-btn-row {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      position: relative;
   }

   .toggle-btn-row>div {
      margin-top: -10px;
      padding: 6px 40px;
   }

   .toggle-btn-row>div>div {
      opacity: 0.9;
      -webkit-transition: 0.1s ease-in-out;
      -moz-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out;
      -ms-transform: rotate(270deg);
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
   }

   .toggle-btn-row>div:hover {
      cursor: pointer;
   }


   .toggle-btn-row.collapsed>div>div {
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
   }

   .toggle-btn-row>div>div img {
      height: 10px;
   }


   /*Tags tobble btn*/
   .mobile-menu .tgl-btn-row {
      padding-bottom: 8px;
   }

}



/* MOBILE */

.mobile-menu {
   background-color: #000F1A;
   color: #fff;
   /* width: 100vw; */
   min-height: 10px;
   padding: 12px;
   overflow-x: hidden;
   flex-shrink: 0;
   padding-bottom: 0px;
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   border-radius: 10px 10px 0 0;
   max-height: calc(100vh - 94px); /*navbar height 60 + title cell height 34px*/


   .time-frame {
      margin-bottom: 12px;
      height: 38px;
   }
   .time-frame .arrow {
      position: absolute;
      left: calc(50% - 24px);
      /* background-color: red; */
      border-top: solid 1px #0075BE;
      border-right: solid 1px #0075BE;
      height: 28px;
      width: 27px;
      top: 4px;
      transform: rotate(45deg);
      /* z-index: 0; */
   }

   .data-info {
      background-color: #040507;
      margin-bottom: 12px;
      margin-top: 0px;
      padding: 12px;
      padding-top: 12px;
      min-height: 0px;
      overflow-y: auto;
      max-height: 400px;
   }

   .data-info .data-actions-btn {
      margin-top: 0px;
   }

}
