div.options-dropdown-container {
   position: absolute;
   top: 8px;
   right: 10px;
}

div.chart-options-container {
   padding: 8px 12px;
}

div.chart-options label.tgl-btn {
   margin-bottom: 2px;
}

div.chart-options p {
   margin: 0;
   margin-right: 8px;
}

input.scale-input {
   width: 60px;
   margin-right: 12px;
}
