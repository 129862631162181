.chart {
   user-select: none;
   overflow: hidden;
}

.chart > .rv-discrete-color-legend {
   margin-left: 56px;
   height: 40px !important;
}

.chart .rv-crosshair.crosshair--nearest-item > .rv-crosshair__line {
   background-color: #0075be;
   opacity: 0.3;
   width: 3px;
   margin-left: -1px;
}

.chart .rv-highlight-container > .rv-highlight {
   fill: #0075be;
   opacity: 0.3;
}

.chart .rv-crosshair.crosshair--selected-item > .rv-crosshair__line {
   background-color: #818181;
   opacity: 0.2;
   width: 3px;
   margin-left: -1px;
}

.chart .rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
   display: inline-block;
   margin-left: 8px;
   font-size: 14px;
   font-weight: 300;
}

.chart .rv-crosshair > .rv-crosshair__inner {
   z-index: 1;
}

.chart .rv-discrete-color-legend-item__color {
   padding-bottom: 4px;
}

.chart .highcharts-scrollbar-arrow {
   display: none
}

.chart > .message-over-chart {
   pointer-events: none;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   text-align: center;
   transition: opacity 0.4s;
}

.chart > .message-over-chart > div {
   position: absolute;
   top: 70px;
   left: 0;
   right: 0;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
}

.chart > .message-over-chart p {
   padding: 4px 12px;
   border-radius: 10px;
   background: #000000ee;
   color: white;
   margin: 0;
}

/* Hide Highchart reset zoom button */
.chart .highcharts-reset-zoom {
   display: none;
}
