.hover-info-box {
   z-index: 2;
   position: absolute;
   padding: 12px;
   min-width: 60px;
   max-width: 300px;
   margin: 8px;
   background-color: #fff;
   border-radius: 2px;
   outline: 1px solid rgba(0, 0, 0, 0.08);
   box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
}
