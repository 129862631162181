/**
 * @license
 * MyFonts Webfont Build ID 3615566, 2018-07-27T05:49:10-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Gilroy-SemiBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold/
 *
 * Webfont: Gilroy-Regular by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular/
 *
 * Webfont: Gilroy-ExtraBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/extra-bold/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3615566
 * Licensed pageviews: 500,000
 * Webfonts copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 * © 2018 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
/* @import url("//hello.myfonts.net/count/372b4e"); */

/*SemiBold*/
@font-face {font-family: 'Gilroy';font-weight: 400;src: url('assets/fonts/372B4E_0_0.eot');src: url('assets/fonts/372B4E_0_0.eot?#iefix') format('embedded-opentype'),url('assets/fonts/372B4E_0_0.woff2') format('woff2'),url('assets/fonts/372B4E_0_0.woff') format('woff'),url('assets/fonts/372B4E_0_0.ttf') format('truetype');}

/*Regular*/
@font-face {font-family: 'Gilroy';font-weight: 300;src: url('assets/fonts/372B4E_1_0.eot');src: url('assets/fonts/372B4E_1_0.eot?#iefix') format('embedded-opentype'),url('assets/fonts/372B4E_1_0.woff2') format('woff2'),url('assets/fonts/372B4E_1_0.woff') format('woff'),url('assets/fonts/372B4E_1_0.ttf') format('truetype');}

/* ExtraBold */
@font-face {font-family: 'Gilroy';font-weight: 600;src: url('assets/fonts/372B4E_2_0.eot');src: url('assets/fonts/372B4E_2_0.eot?#iefix') format('embedded-opentype'),url('assets/fonts/372B4E_2_0.woff2') format('woff2'),url('assets/fonts/372B4E_2_0.woff') format('woff'),url('assets/fonts/372B4E_2_0.ttf') format('truetype');}


body {
   font-family: 'Gilroy', sans-serif;
   font-weight: 300;
   margin: 0;
   padding: 0;
   word-wrap: break-word;
   color: #333;
   background-color: #F0F0F0;
}

h1 {

}
h2 {
   font-weight: 400;
   font-size: 24px;
}
h3 {
   font-size: 20px;
   font-weight: 400;
}

h4 {
   font-size: 16px;
   font-weight: 400;
}

p {
   font-size: 16px;
   line-height: 22px;
}

/* p.small {
   font-size: 12px;
   color: #989898;
} */


button:focus, a:focus {
   outline:0;
}



/* Bootstrap v4 dependency was removed but some of the styles that were kept under here */
p {
   margin-top: 0px;
}
img {
   vertical-align: middle;
}
*, ::after, ::before {
   box-sizing: border-box;
}
.dropdown-divider {
   border-top: 1px solid #e9ecef;
   height: 0;
   margin: 0.5rem 0;
   overflow: hidden;
}
html { line-height: 1.15; }
body { line-height: 1.5; }
button, input, optgroup, select, textarea {
   font-family: inherit;
   font-size: inherit;
   line-height: inherit;
   margin: 0;
}
h1, h2, h3, h4, h5, h6 {
   margin-bottom: 0.5rem;
   margin-top: 0;
   line-height: 1.2;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
   cursor: pointer;
}
hr {
   border: 0;
   border-top: 1px solid rgba(0, 0, 0, 0.1);
   margin-bottom: 16px;
}
