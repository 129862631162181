.radio-button {
   margin-bottom: 6px;
   display: flex;
   flex-direction: row;
   padding-left: 12px;
   cursor: pointer;
}
.radio-button p {
   margin-bottom: 0;
   margin-left: 8px;
}

/* input.form-check-input {
   margin-top: 1px;
} */

label.form-check-label {
   margin-left: 4px;
}

.radio-button input {
   opacity: 0;
   height: 0;
   width: 0;
}

 /* Create a custom checkbox */
 .radio-button .checkmark {
   left: 0;
   height: 18px;
   width: 18px;
   background-color: #eee;
   border-radius: 50%;
   border: solid #cccccc 1px;
}

 /* On mouse-over, add a grey background color */
.radio-button:hover input ~ .checkmark {
   background-color: #ccc;
   cursor: pointer;
}

/* When the radio button is checked, add a blue background */
.radio-button input:checked ~ .checkmark {
   background-color: #0075BE;
   border-color: #0075BE;
}
