#user-management-modal .user-management-row {
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 40px;
   border-bottom: solid 1px #e1e1e1;
}

#user-management-modal .selectable-user-row {
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-bottom: 6px;
   user-select: text;
   -moz-user-select: text;
}


#user-management-modal .role-and-action-btn {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   width: 120px;
}

#user-management-modal .role-and-action-btn button.button.icon  {
   height: 24px;
   margin-top: 14px;
}

#user-management-modal .allowed-sites-row {
   display: flex;
   flex-direction: row;
   align-items: baseline;
}

#user-management-modal .allowed-sites-tgl-container {
   display: flex;
   right: 0;
   flex-direction: row;
   align-items: right;
   margin-left: auto;
   margin-right: 6px;
   /* margin-left: 56px; */
}
#user-management-modal .allowed-sites-tgl-container .toggle-btn {
   margin-left: 24px;
}

#user-management-modal .user-manage-view input{
   margin-bottom: 12px;
}
