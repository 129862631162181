


.card-container h3 {
   color: #989898;
}

.card {
   background-color: #fff;
   padding:  24px 16px;
   border: none;
   border-radius: 0;
   position: relative;
   margin-bottom: 14px;
   display: block;
   outline: 1px solid rgba(0, 0, 0, 0.012);
   box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
}

.card.is-full-screen {
   position: fixed;
   height: 100vh;
   width: 100vw;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 15;
}

.card--outline {
   outline: solid #0075BE 1px;

}
.card--outline-danger {
   outline: solid #be0000 1px;
}

.card .button-row {
   position: absolute;
   top: 7px;
   right: 8px;
   z-index: 3;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-end;
   height: 30px;
}

.bottom-row {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   margin-bottom: -12px;
   margin-top: 12px;
}

.card-menu-btn button.button {
   margin: 0;
   max-height: 24px;
}

.card-close-btn-container button.button {
   max-height: 22px;
   margin: 0;
}

.card-minimize-button button.button {
   max-height: 22px;
   margin: 0;
   display: block;
}

.card-minimize-button button.button img {
   width: 24px;
}


button.expand-card-btn {
   background: none;
   border: none;
   color: #333;
   font-weight: 300;
}

button.expand-card-btn:hover {
   cursor: pointer;
}

/* button.expand-card-btn img.expand-arrow {
   margin-left: 8px;
   height: 12px;
   width: 12px;
   margin-top: -2px;
   -webkit-transition: 0.1s ease-in-out;
   -moz-transition: 0.1s ease-in-out;
   -o-transition: 0.1s ease-in-out;
   transition: 0.1s ease-in-out;
   -ms-transform: rotate(270deg);
   -webkit-transform: rotate(270deg);
   transform: rotate(270deg);
}

button.expand-card-btn img.expand-arrow.expanded {
   -ms-transform: rotate(90deg);
   -webkit-transform: rotate(90deg);
   transform: rotate(90deg)
} */


/*MOBILE*/
.card-container.mobile {
   padding: 0 8px;
}

.card-container.mobile .card {
   padding:  16px 8px;
}
