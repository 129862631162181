@import '../../shared';

.mobile-navbar {
   background-color: #000F1A;
   color: #fff;
   width: 100vw;
   min-height: $navbar_height;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 101;
   border-bottom: solid rgba(255, 255, 255, 0.1) 1px;
}

.mobile-ava-logo {
   position: absolute;
   top: 21px;
   left: 12px;
   height: 18px;
}

.mobile-navbar p.title {
   position: absolute;
   top: 22px;
   width: 100%;
   z-index: 1;
   text-align: center;
   font-weight: 400;
   font-size: 18px;
}

.back-btn-container {
   position: absolute;
   left: 0px;
   height: 38px;
   padding: 6px;
   top: 12px;
   z-index: 2;
}

.toggle-menu-btn {
   position: absolute;
   right: 12px;
   height: 28px;
   padding: 6px;
   top: 12px;
   width: auto;
   background-color: transparent;
   outline: none;
   border: none;
   z-index: 2;
}

.menu-items {
   padding-top: 60px;
}

img.toggle-icon {
   max-height: 100%;
}

.toggle-menu-item {
   font-weight: 400;
   width: 100%;
   text-align: center;
}

.toggle-menu-item p {
   font-size: 16px;
   font-weight: 300;
   width: 100%;
   margin: 0;
   padding-bottom: 20px;
}

.toggle-menu-item.disabled p {
   opacity: 0.4;
}
