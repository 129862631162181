@import '../../shared';

.settings-container {

   &__content-container {
      display: flex;
      flex-direction: row;

   }

   &__inner-container {
      height: calc(100vh - #{$navbar_height});
      overflow-y: hidden;
      width: calc(100% - 236px); // Side menu width === 236px
      // padding: 24px;

      &.mobile {
         width: 100%;
         height: calc(100vh - 60px); // 60px === mobile navbar height
         padding-top: 60px; // mobile navbar height
      }

   }

}
