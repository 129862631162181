.data-modal {
   -ms-overflow-x: hidden; /* ie fix */
   padding-bottom: 108px;
}
.data-modal--mobile {
   padding-bottom: 148px;
}

.loader-container {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   width: 100%;
   flex-direction: column;
}

/* .data-modal h4 {
   color: #989898;
} */


.data-modal .time-selector {
   border: solid 1px #0075BE;
   display: flex;
   height: 36px;
   align-items: center;
   position: relative;
   /* overflow: hidden;  this prevents ditapicker to be visible*/
   margin-top: 12px;
}

/* .data-modal .time-selector,
.data-modal .react-select {
   width: 100%;
} */


.data-modal .time-selector .left-side p,
.data-modal .time-selector .right-side p {
   height: 100%;
   text-align: center;
   margin: 0;
   padding-top: 6px;
   font-weight: 300;
   z-index: 4;
}

.data-modal .time-selector .right-side,
.data-modal .time-selector .left-side {
   width: 50%;
   text-align: center;
   z-index: 2;
   height: 100%;
   color: #0075BE;
   background-color: #fff;
}

.data-modal .time-selector .right-side:not(.right-side--active):not(:active):hover,
.data-modal .time-selector .left-side:not(.left-side--active):not(:active):hover {
   background-color: #3391cb;
   color: #fff;
}

.data-modal .time-selector .right-side--active,
.data-modal .time-selector .left-side--active,
.data-modal .time-selector .right-side:active,
.data-modal .time-selector .left-side:active {
   background-color: #0075BE;
   color: #fff;
}

.data-modal .date-picker-btn-right,
.data-modal .date-picker-btn {
   color: #0075BE;
}

.data-modal .time-selector {
   cursor: pointer;
}

.data-modal .time-selector .left-side--active + .arrow,
.data-modal .time-selector .left-side:active + .arrow {
   background-color: #0075BE;
}
.data-modal .time-selector .left-side:not(.left-side--active):not(:active):hover + .arrow {
   background-color:#3391cb;
}
/* .time-selector .right-side {
}
.time-selector .left-side {
} */

.data-modal .time-selector .arrow {
   position: absolute;
   left: calc(50% - 13px);
   pointer-events: none;
   /* background-color: red; */
   border-top: solid 1px #0075BE;
   border-right: solid 1px #0075BE;
   height: 24px;
   width: 25px;
   top: 5px;
   transform: rotate(45deg);
   z-index: 3;
   background-color: #fff;
}





/*Date picker component*/
/*Date picker button is streched to cover the parent element. Hence to selector shows when .date-picker-btn is clicked*/

.data-modal .date-picker-btn,
.data-modal .date-picker-btn-right {
   /*background-color: red;*/
   width: 100%;
   margin-top: -32px;
}

.react-datepicker-wrapper {
   width: 100%;
   opacity: 0;
   height: 1px;
}
.react-datepicker-wrapper:hover,
.react-datepicker-wrapper input:hover
{
   cursor: pointer;
}
.react-datepicker__input-container {
   width: 100%;
   height: 1px;
}
.react-datepicker__input-container>input {
   width: 100%;
   height: 1px;
}

/* Hack to prevent overflow on mobile */
.mobile-left-deta-picker .react-datepicker {
   margin-left: -200px;
}
.mobile-left-deta-picker .react-datepicker div.react-datepicker__triangle {
   margin-left: 80px;
}



/* .data-modal .react-datepicker__input-container {
   opacity: 1;
   height: 1px;
}

.data-modal .react-datepicker__input-container>input {
   height: 1px;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   width: 100%;
   opacity: 0;
}

.data-modal .react-datepicker__input-container>input:hover {
   cursor: pointer;
} */

/* .data-modal .date-picker-btn,
.data-modal .date-picker-btn-right {
   position: absolute;
   top: 0;
   bottom: 0;
   z-index: 10;
   width: 50%;
}
.data-modal .date-picker-btn {
   left: 0;
   overflow: show;
}
.data-modal .date-picker-btn-right {
   right: 0;
}
.data-modal .date-picker-btn .react-date-picker,
.data-modal .date-picker-btn-right .react-date-picker {
   width: 100%;
   height: 100%;
}

.data-modal .react-date-picker .react-date-picker__wrapper,
.data-modal .react-date-picker .react-date-picker__calendar-button__icon {
   opacity: 0;
}
.data-modal .react-date-picker .react-date-picker__wrapper input,
.data-modal .react-date-picker .react-date-picker__wrapper button:not(.react-date-picker__calendar-button) {
   display: none;
   pointer-events: none;
}
.data-modal .react-date-picker .react-date-picker__calendar-button {
   position: absolute;
   width: 100%;
   left: 0;
   top: 0;
   bottom: 0;
}
.data-modal .react-date-picker .react-calendar__tile--active {
   background-color: #0075BE;
}

.data-modal .date-picker-btn-right .react-calendar {
   position: -ms-device-fixed;
} */
