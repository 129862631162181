@import '../../shared';

.page-tool-container {
   height: 100vh;
   width: 100vw;
   display: flex;
   flex-direction: column;
}

.page-tool {
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: row;
   overflow: hidden;
}

.tool-content-container {
   width: calc(100vw - #{$side_menu_width});
   display: flex;
   flex-direction: column;
}

.tool-content-container.is-full-screen {
   width: 100vw;
   height: 100vh;
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 10;
}


.andritz-logo-container {
   opacity: 0;
   transition: opacity 0.225s ease-in;
}

.andritz-logo-container,
.ongoing-analysis-loading-spinner-container {
   position: absolute;
   bottom: 0;
   top: 0;
   left: 0;
   right: 0;
   display: flex;
   align-items: center;
   justify-content: center;
}

/*TODO: This was temp fix for demo. Create new loading sinner for mobile*/
.mobile-ongoing-spinner {
   padding: 20px;
   border-radius: 6px;
   background-color: rgba(256, 256, 256, 1);
   z-index: 100000000;
   width: 100px;
   height: 100px;
   position: fixed;
   top: calc(50% - 49px);
   left: calc(50% - 49px);
   border: 1px #e1e1e1 solid;
}

.andritz-logo {
   opacity: 0.15;
   width: 440px;
   max-width: 90%;
}

/*MOBILE*/

.menu-and-data {
   /* height: calc(100vh - 60px); full height - navbar */
   /* display: flex;
   flex-direction: column; */
}
.page-tool-mobile {
   padding-top: 60px; /*Fixed navbar height*/
}
